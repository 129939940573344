import './App.css';
import {HashRouter, Route} from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsConfig from './config/aws-exports';
import { Switch, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { isExpired } from 'react-jwt';
import React, { lazy, Suspense, useEffect } from 'react';
import { clearAppUser, setAppUser } from './slices/appStateSlice';
import 'bootstrap/dist/css/bootstrap.min.css';

const auth = lazy(() => import('./components/layouts/auth/AuthLayout'));
const app = lazy(() => import('./components/layouts/main/AppLayout'));
const outside = lazy(() => import('./components/layouts/outside/outsideLayout'));
Amplify.configure(awsConfig);

function App() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
    const getCurrentAuthenticatedUser = () => {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then((user) => {
          const token = user.signInUserSession.accessToken.jwtToken;
          const isMyTokenExpired = isExpired(token);
          if (isMyTokenExpired) {
            localStorage.clear();
            dispatch(clearAppUser());
            history?.replace('/');
          } else {
            axios.defaults.headers.Authorization = 'Bearer ' + token;
            axios.interceptors.response.use(
              (response) => {
                if (response.status === 401) {
                  history?.replace('/auth/signedout');
                  dispatch(clearAppUser());
                  localStorage.clear();
                } else {
                  return response;
                }
              },
              (error) => {
                if (error.response.status === 401) {
                  history?.replace('/auth/signedout');
                  dispatch(clearAppUser());
                  localStorage.clear();
                } else {
                  return Promise.reject(error);
                }
              }
            );

            dispatch(
              setAppUser({
                username: user.username,
                attributes: user.attributes,
                token: token,
              })
            );
          }
        })
        .catch(() => {
          dispatch(clearAppUser());
          localStorage.clear();
        });
    };
    getCurrentAuthenticatedUser();
  }, [history, dispatch]);
  return (
    <HashRouter>
      <Suspense fallback={''}>
        <Switch>
          <Route exact path="/" component={auth} />
          <Route path={'/outside'} component={outside} />
          <Route path="/auth" component={auth} />
          <Route path="/app" component={app} />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}

export default App;

import { configureStore } from '@reduxjs/toolkit';
import appStateReducer from '../slices/appStateSlice';
import mainStateReducer from '../slices/mainStateSlice';


export default configureStore({
  reducer: {
    appState: appStateReducer,
    mainState: mainStateReducer
  },
});
